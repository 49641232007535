import React, { useEffect, useState } from "react"
import "./signup.css"
import { useSpinner } from "src/context/loaderContext/globalSpinnerContext";
import logo from "src/assets/images/dropdsmall.svg"
import search from "src/assets/images/search.svg"
import attributes from "src/assets/images/attributes.png"
import dropdLaunchScreen from "src/assets/images/home-landing.png"
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { launchNextPage } from "src/PageLauncher";
import { chains } from "../../chain";
import {
  createSmartAccountClient,
  BiconomySmartAccountV2,
  PaymasterMode,
} from "@biconomy/account";
import { ethers } from "ethers";
import { Web3Auth } from "@web3auth/modal";
import { CHAIN_NAMESPACES, WALLET_ADAPTERS } from "@web3auth/base";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
import { contractABI } from "../../contract/contractABI";
import { useAuth } from 'src/context/web3authProvider/AuthContext';

// import InAppBrowserPopup from '../../components/InAppBrowserPopup/InAppBrowserPopup';

// import MaintenancePopup from '../../components/MaintenancePopup/MaintenancePopup';

function Signup() {
  const { t, i18n } = useTranslation();
  const history = useNavigate();
  const [smartAccount, setSmartAccount] = useState(null);
  const [smartAccountAddress, setSmartAccountAddress] = useState(null);
  const [txnHash, setTxnHash] = useState(null);
  const [hash,setHash] = useState(window.location.hash)
  const { authProvider, setAuthProvider } = useAuth();
  const spinner = useSpinner();

  const connect = async () => {
    spinner.setLoadingState(true);
  
    try {
      const chainConfig =
          {
            chainNamespace: CHAIN_NAMESPACES.EIP155,
            chainId: chains.chainIdHex,
            rpcTarget: chains.providerUrl,
            displayName: chains.name,
            blockExplorer: chains.explorerUrl,
            ticker: "MATIC",
            tickerName: chains.name,
          }


      //Creating web3auth instance
      const web3auth = new Web3Auth({
        authMode: "WALLET",
        // to do (Client Id should be change to our)
        clientId: chains.web3authClientId, // Get your Client ID from the Web3Auth Dashboard https://dashboard.web3auth.io/
        web3AuthNetwork: "sapphire_devnet", // Web3Auth Network
        chainConfig,
        uiConfig: {
          appName: "Web3",
          mode: "dark", // light, dark or auto
          loginMethodsOrder: ["google"],
          logoLight: "https://web3auth.io/images/web3auth-logo.svg",
          logoDark: "https://web3auth.io/images/web3auth-logo---Dark.svg",
          defaultLanguage: "en", // en, de, ja, ko, zh, es, fr, pt, nl
          loginGridCol: 3,
          primaryButton: "emailLogin", // "externalLogin" | "socialLogin" | "emailLogin"
        },
      });


      const openloginAdapter = new OpenloginAdapter({
        loginSettings: {
          mfaLevel: "optional",
        },
        adapterSettings: {
          uxMode: "redirect", // "redirect" | "popup"
          whiteLabel: {
            name: "Web3",
            logoLight: "https://web3auth.io/images/w3a-L-Favicon-1.svg",
            logoDark: "https://web3auth.io/images/w3a-D-Favicon-1.svg",
            defaultLanguage: "en", // en, de, ja, ko, zh, es, fr, pt, nl
            dark: true, // whether to enable dark mode. defaultValue: false
          },
          mfaSettings: {
            deviceShareFactor: {
              enable: true,
              priority: 1,
              mandatory: true,
            },
            backUpShareFactor: {
              enable: true,
              priority: 2,
              mandatory: false,
            },
            socialBackupFactor: {
              enable: true,
              priority: 3,
              mandatory: false,
            },
            passwordFactor: {
              enable: true,
              priority: 4,
              mandatory: false,
            },
          },
        },
      });
      web3auth.configureAdapter(openloginAdapter);

      await web3auth.initModal({
        modalConfig: {
          [WALLET_ADAPTERS.OPENLOGIN]: {
            label: "openlogin",
            loginMethods: {
              // Disable options
              facebook: {
                name: "facebook",
                showOnModal: false,
              },
              reddit: {
                name: "reddit",
                showOnModal: false,
              },
              twitter: {
                name: "twitter",
                showOnModal: false,
              },
              discord: {
                name: "discord",
                showOnModal: false,
              },
              twitch: {
                name: "twitch",
                showOnModal: false,
              },
              apple: {
                name: "apple",
                showOnModal: false,
              },
              github: {
                name: "github",
                showOnModal: false,
              },
              line: {
                name: "line",
                showOnModal: false,
              },
              linkedin: {
                name: "linkedin",
                showOnModal: false,
              },
              kakao: {
                name: "kakao",
                showOnModal: false,
              },
              weibo: {
                name: "weibo",
                showOnModal: false,
              },
              wechat: {
                name: "wechat",
                showOnModal: false,
              },
              sms_passwordless: {
                name: "sms_passwordless",
                showOnModal: false,
              },
            },
          },
        },
      });
      const web3authProvider = await web3auth.connect();

      const userInfo = await web3auth.getUserInfo();
      const userEmail = userInfo.email;
      localStorage.setItem("email", userEmail);

      const ethersProvider = new ethers.providers.Web3Provider(
        web3authProvider
      );
      const web3AuthSigner = ethersProvider.getSigner();

      const config = {
        biconomyPaymasterApiKey: chains.biconomyPaymasterApiKey,
        bundlerUrl: chains.biconomyBundlerUrl, // <-- Read about this at https://docs.biconomy.io/dashboard#bundler-url
      };
      const smartWallet = await createSmartAccountClient({
        signer: web3AuthSigner,
        biconomyPaymasterApiKey: config.biconomyPaymasterApiKey,
        bundlerUrl: config.bundlerUrl,
        rpcUrl: chains.providerUrl,
        chainId: Number(chains.chainId),
      });


      setSmartAccount(smartWallet);

      // localStorage.setItem("smart_wallet", smartWallet);
      setAuthProvider(smartWallet);

      const saAddress = await smartWallet.getAccountAddress();
      
      setSmartAccountAddress(saAddress);

      await launchNextPage(history, "/timeline", saAddress);

    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {

    if(hash){
      const query = new URLSearchParams(hash.substring(1));
  
      // Step 3: Get the b64Params and decode it
      const b64Params = query.get('b64Params');
      if (b64Params) {
        try {
          connect()
        } catch (error) {
          console.error('Error decoding b64Params:', error);
        }
      }
    }
  }, [hash]);


  return (
    <div className='signup-container'>
      <div className="signup-wrap">
        <div className='feature-box'>
          <img src={dropdLaunchScreen} alt="DropD" />
        </div>
        <div className='button-container'>
          <button onClick={connect} className='signup-button' variant='contained'>{"Let's begin"}</button>
        </div>
      </div>

      {/*<InAppBrowserPopup />*/}
    </div>
  )
}

export default Signup;